const create = (api) => {
  const getOperators = () => {
    return api.get(`/voice/operators`)
  }

  return {
    getOperators
  }
}

export default {
  create
}
