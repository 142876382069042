const pagination = {
  pageNumber: 1,
  pageSize: 100
}

const create = (api) => {
  const usersList = (pageParams = pagination) => api.get('/UserManagement/v2/users', pageParams)

  const updateUser = (userData) => api.put(`/UserManagement/v2/users`, userData)

  const deleteUser = (userId, orgId) => api.delete(`/UserManagement/users/${userId}`, undefined, { data: { sites: orgId } })

  const createUser = (userData) => api.post('/UserManagement/v2/users/', userData)

  const getRoles = (pageParams = pagination) => api.get('/UserManagement/users/roles', pageParams)

  const getRegistrationRole = () => api.get('/usermanagement/users/roles/device_registration')

  const getUiRoles = (pageParams = pagination) => api.get('/UserManagement/users/roles', { source: 'ui', ...pageParams })

  const getUsersByRole = (roleId, pageParams = pagination) => api.get('/UserManagement/users/role', { roleId, ...pageParams })

  const getUsers = (tableParams) => api.get('/UserManagement/v2/users/', tableParams)

  return {
    usersList,
    updateUser,
    deleteUser,
    createUser,
    getRoles,
    getRegistrationRole,
    getUiRoles,
    getUsersByRole,
    getUsers
  }
}

export default {
  create
}
