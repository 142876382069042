import PropTypes from 'prop-types';

export const flagsPropTypes = PropTypes.shape({
  multilanguage: PropTypes.bool,
  multilanguageEn: PropTypes.bool,
  multilanguageDe: PropTypes.bool,
  multilanguageEs: PropTypes.bool,
  multilanguageFr: PropTypes.bool,
  multilanguageZh: PropTypes.bool,
  legacySiteManagement: PropTypes.bool,
  siteManagementPreview: PropTypes.bool,
  menuIndoorLocation: PropTypes.bool,
  enterpriseProvisioner: PropTypes.bool,
  notificationBell: PropTypes.bool,
  notificationPreferences: PropTypes.bool,
  notificationsDashboard: PropTypes.bool,
  editDashboardButton: PropTypes.bool,
  releaseDashboard: PropTypes.bool,
  batteriesCycleCount: PropTypes.bool,
  batteriesWithLowHealth: PropTypes.bool,
  agingBatteries: PropTypes.bool,
  batteryCycleAbove300: PropTypes.bool,
  batteryHealthBelow50: PropTypes.bool,
  batteryLowHealthEvents: PropTypes.bool,
  batteryHealthBetween50And75: PropTypes.bool,
  batterySwaps: PropTypes.bool,
  dashboardFilterSitesTree: PropTypes.bool,
  dashboardFilterSitesSelector: PropTypes.bool,
  totalDevices: PropTypes.bool,
  countSitesWithDevices: PropTypes.bool,
  totalDevicesWithBatteries: PropTypes.bool,
  totalBatteries: PropTypes.bool,
  totalDevicesType: PropTypes.bool,
  devicesBySite: PropTypes.bool,
  totalDevicesModel: PropTypes.bool,
  offlineDevicesByType: PropTypes.bool,
  offlineDevicesBySite: PropTypes.bool,
  offlineDevicesByModel: PropTypes.bool,
  distributionOfDisruptions: PropTypes.bool,
  bulkEditDevices: PropTypes.bool,
  reboots: PropTypes.bool,
  printersFirmwareVersion: PropTypes.bool,
  printerUsageByLabels: PropTypes.bool,
  printerUsageByOdometer: PropTypes.bool,
  printersPrintVolume: PropTypes.bool,
  mobilePrinterUsageByLabels: PropTypes.bool,
  mobilePrinterUsageByMeters: PropTypes.bool,
  printersFaultyDot: PropTypes.bool,
  printerStatus: PropTypes.bool,
  devicesByUsageLevels: PropTypes.bool,
  hardUseDevices: PropTypes.bool,
  mobileOsVersion: PropTypes.bool,
  droppedDevicesCard: PropTypes.bool,
  devicesSecurityPatch: PropTypes.bool,
  scanVolumeCard: PropTypes.bool,
  scanFirmwareCard: PropTypes.bool,
  scanQualityCard: PropTypes.bool,
  printVolumeByDevice: PropTypes.bool,
  dropsByDevice: PropTypes.bool,
  applicationReportsSection: PropTypes.bool,
  remoteWipeout: PropTypes.bool,
  remoteViewerControl: PropTypes.bool,
  deviceAlerts: PropTypes.bool,
  newBatteriesCard: PropTypes.bool,
  profileMenuOption: PropTypes.bool,
  settingsMenuOption: PropTypes.bool,
  assetTagsFeature: PropTypes.bool,
  advancedColumns: PropTypes.bool,
  saveFilters: PropTypes.bool,
  softImpacts: PropTypes.bool,
  dashboardControls: PropTypes.bool,
  dashboardSectionsAccordions: PropTypes.bool,
  propertiesCellularData: PropTypes.bool,
  devicePropertiesColumn1: PropTypes.bool,
  devicePropertiesColumn2: PropTypes.bool,
  devicePropertiesColumn3: PropTypes.bool,
  devicePropertiesColumn4: PropTypes.bool,
  settingsSpacing: PropTypes.bool,
  settingsRegion: PropTypes.bool,
  accessPointsManager: PropTypes.bool,
  siteMap: PropTypes.bool,
  lastUserColumn: PropTypes.bool,
  neverused: PropTypes.bool,
  registeredDevices: PropTypes.bool,
  stateManagement: PropTypes.bool,
  roleCreation: PropTypes.bool,
  nonIot: PropTypes.bool,
  peopleCounterApplication: PropTypes.bool,
  assetMaintenance: PropTypes.bool,
  networkRanges: PropTypes.bool,
  deleteFirmwareFile: PropTypes.bool,
  deletedBatteriesCard: PropTypes.bool,
  premiumLevelDefault: PropTypes.bool,
  checkInOutTab: PropTypes.bool,
  rulesEngine: PropTypes.bool,
  automationSelfControl: PropTypes.bool,
  excludeDockingTime: PropTypes.bool,
  refreshConnection: PropTypes.bool,
  lastHeartbeat: PropTypes.bool,
  licenseDeviceDetails: PropTypes.bool,
  siteAnalytics: PropTypes.bool,
  siteComparison: PropTypes.bool,
  siteRanking: PropTypes.bool,
  userActivityReport: PropTypes.bool,
  userReports: PropTypes.bool,
  contactTracingReport: PropTypes.bool,
  eventReport: PropTypes.bool,
  eventReportExport: PropTypes.bool,
  licenseManager: PropTypes.bool,
  checkInCheckOut: PropTypes.bool,
  bulkProvisionDevices: PropTypes.bool,
  networkIo: PropTypes.bool,
  applicationUsageTime: PropTypes.bool,
  nonReportingBatteries: PropTypes.bool,
  customDashboards: PropTypes.bool,
  fullCustomDashboardControls: PropTypes.bool,
  printerGetImageBufferAction: PropTypes.bool,
  printerPullConfigurationAction: PropTypes.bool,
  ruleCreationV2: PropTypes.bool,
  overrideSwUpdateNetwokPrefrerences: PropTypes.bool,
  nonHoneywellDeviceInfo: PropTypes.bool,
  lostAndFound: PropTypes.bool,
  applicationDisruptions: PropTypes.bool,
  applicationDisruptionsByApp: PropTypes.bool,
  applicationDisruptionsByDevice: PropTypes.bool,
  downloadExports: PropTypes.bool,
  deviceAwake: PropTypes.bool,
  advancedLogging: PropTypes.bool,
  hash256: PropTypes.bool,
  licensing: PropTypes.bool,
  renewSession: PropTypes.bool,
  networkCarrier: PropTypes.bool,
  eulaVerification: PropTypes.bool,
  slaVerification: PropTypes.bool,
  mobilePullConfigurationAction: PropTypes.bool,
  assetsDownloadExport: PropTypes.bool,
  deviceStatistics: PropTypes.bool,
  bulkEditUsersByMail: PropTypes.bool,
  mapTracking: PropTypes.bool,
  customerOperationsLicenseView: PropTypes.bool,
  dataSolution: PropTypes.bool,
  swUpdateFromFile: PropTypes.bool,
  editSwUpdateName: PropTypes.bool,
  logAnalyzerTool: PropTypes.bool,
  applicationReport: PropTypes.bool,
  displayManufacturer: PropTypes.bool,
  genericLastTelemetry: PropTypes.bool,
  subscriptionManagement: PropTypes.bool,
  toolsMenu: PropTypes.bool,
  deviceBatteryDischarge: PropTypes.bool,
  batteryDetails: PropTypes.bool,
  batteryUsageDetails: PropTypes.bool,
  batteryUsageByApp: PropTypes.bool,
  batteryUsageActivity: PropTypes.bool,
  batteryUsageLastCharged: PropTypes.bool,
  swUpdateNewDetails: PropTypes.bool,
  dashboardBatteryDischarge: PropTypes.bool,
  workForceIntelligence: PropTypes.bool,
  enforceNoCheckoutDeleteDevices: PropTypes.bool,
  cellularMonitoring: PropTypes.bool,
  addLicensesBanner: PropTypes.bool,
  apiCredentialsManagement: PropTypes.bool,
  anomalyOverview: PropTypes.bool,
  swfwAllowDownloadFile: PropTypes.bool,
  swfwAllowAdvancedDownloadFile: PropTypes.bool,
  rmaOverview: PropTypes.bool,
  rma: PropTypes.bool,
});

// Default LaunchDarkly flags.
// These flags should be set according to what happens if LaunchDarkly can't load or is loading.
// For security, only enable features that you want to display while LaunchDarkly is loading.
export const defaultFlags = {
  multilanguage: false,
  multilanguageEn: true,
  multilanguageDe: true,
  multilanguageEs: true,
  multilanguageFr: true,
  multilanguageZh: false,
  legacySiteManagement: false,
  siteManagementPreview: true,
  menuIndoorLocation: false,
  enterpriseProvisioner: true,
  notificationBell: true,
  notificationPreferences: false,
  notificationsDashboard: false,
  editDashboardButton: false,
  releaseDashboard: true,
  batteriesCycleCount: true,
  batteriesWithLowHealth: true,
  agingBatteries: true,
  batteryCycleAbove300: true,
  batteryHealthBelow50: true,
  batteryLowHealthEvents: true,
  batteryHealthBetween50And75: true,
  batterySwaps: true,
  dashboardFilterSitesTree: false,
  dashboardFilterSitesSelector: true,
  totalDevices: true,
  countSitesWithDevices: true,
  totalDevicesWithBatteries: true,
  totalBatteries: true,
  totalDevicesType: true,
  devicesBySite: true,
  totalDevicesModel: true,
  offlineDevicesByType: true,
  offlineDevicesBySite: true,
  offlineDevicesByModel: true,
  distributionOfDisruptions: false,
  bulkEditDevices: true,
  reboots: true,
  printersFirmwareVersion: true,
  printerUsageByLabels: true,
  printerUsageByOdometer: true,
  printersPrintVolume: true,
  mobilePrinterUsageByLabels: true,
  mobilePrinterUsageByMeters: true,
  printersFaultyDot: true,
  printerStatus: true,
  devicesByUsageLevels: true,
  hardUseDevices: true,
  mobileOsVersion: true,
  droppedDevicesCard: true,
  devicesSecurityPatch: true,
  scanVolumeCard: true,
  scanFirmwareCard: true,
  scanQualityCard: true,
  printVolumeByDevice: true,
  dropsByDevice: true,
  applicationReportsSection: true,
  remoteWipeout: false,
  remoteViewerControl: true,
  deviceAlerts: true,
  newBatteriesCard: true,
  profileMenuOption: true,
  settingsMenuOption: true,
  assetTagsFeature: true,
  advancedColumns: true,
  saveFilters: false,
  softImpacts: false,
  dashboardControls: true,
  dashboardSectionsAccordions: true,
  propertiesCellularData: true,
  devicePropertiesColumn1: true,
  devicePropertiesColumn2: true,
  devicePropertiesColumn3: true,
  devicePropertiesColumn4: true,
  settingsSpacing: false,
  settingsRegion: false,
  accessPointsManager: true,
  siteMap: false,
  lastUserColumn: false,
  neverused: true,
  registeredDevices: true,
  stateManagement: true,
  roleCreation: false,
  nonIot: true,
  peopleCounterApplication: false,
  assetMaintenance: true,
  networkRanges: true,
  deleteFirmwareFile: true,
  deletedBatteriesCard: true,
  premiumLevelDefault: true,
  checkInOutTab: true,
  rulesEngine: true,
  automationSelfControl: false,
  excludeDockingTime: true,
  refreshConnection: true,
  lastHeartbeat: true,
  licenseDeviceDetails: true,
  siteAnalytics: true,
  siteComparison: true,
  siteRanking: true,
  userActivityReport: true,
  userReports: true,
  contactTracingReport: true,
  eventReport: true,
  eventReportExport: true,
  licenseManager: true,
  checkInCheckOut: true,
  bulkProvisionDevices: true,
  networkIo: true,
  applicationUsageTime: true,
  nonReportingBatteries: true,
  customDashboards: true,
  fullCustomDashboardControls: true,
  printerGetImageBufferAction: true,
  printerPullConfigurationAction: true,
  ruleCreationV2: false,
  overrideSwUpdateNetwokPrefrerences: true,
  nonHoneywellDeviceInfo: true,
  lostAndFound: false,
  applicationDisruptions: false,
  applicationDisruptionsByApp: false,
  applicationDisruptionsByDevice: false,
  downloadExports: false,
  deviceAwake: false,
  advancedLogging: false,
  hash256: false,
  licensing: false,
  renewSession: false,
  networkCarrier: false,
  eulaVerification: true,
  slaVerification: true,
  mobilePullConfigurationAction: false,
  assetsDownloadExport: false,
  deviceStatistics: false,
  bulkEditUsersByMail: false,
  mapTracking: false,
  customerOperationsLicenseView: false,
  dataSolution: false,
  swUpdateFromFile: false,
  editSwUpdateName: false,
  logAnalyzerTool: false,
  applicationReport: false,
  displayManufacturer: false,
  genericLastTelemetry: false,
  subscriptionManagement: false,
  toolsMenu: true,
  deviceBatteryDischarge: false,
  batteryDetails: false,
  batteryUsageDetails: false,
  batteryUsageByApp: false,
  batteryUsageActivity: false,
  batteryUsageLastCharged: false,
  swUpdateNewDetails: false,
  dashboardBatteryDischarge: false,
  workForceIntelligence: false,
  enforceNoCheckoutDeleteDevices: false,
  cellularMonitoring: false,
  addLicensesBanner: true,
  apiCredentialsManagement: false,
  anomalyOverview: false,
  swfwAllowDownloadFile: false,
  swfwAllowAdvancedDownloadFile: false,
  rmaOverview: false,
  rma: false,
};

export type LaunchDarklyFlags = typeof defaultFlags
